import request from '../utils/request';
import {BASE_URL_PRO} from "../common/const.js";
// let baseURL = 'http://139.224.25.39:9110/admin/';
// let baseURL = 'http://139.224.25.39:9120/index.php';
let baseURL = BASE_URL_PRO;
export const fetchData = query => {
    return request({
        url: query.url,
        method: query.method,
        params: query.data,
        baseURL: baseURL // 直接通过覆盖的方式
    });
};